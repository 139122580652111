import BaseEntity from 'js/base_v3/models/entity';
import Part from 'js/part/models/part';

const staticSelf = CorporatePart;

/**
 * @const
 */
staticSelf.PRICING_TYPE_CALCULATED = 'calculated';

/**
 * @const
 */
staticSelf.PRICING_TYPE_MANUAL = 'manual';

/**
 * @const
 */
staticSelf.PACKAGE_TYPE_PACKAGED = 'packaged';

/**
 * @const
 */
staticSelf.PACKAGE_TYPE_INDIVIDUAL = 'individual';

/**
 * @const
 */
staticSelf.PACKAGE_TYPE_CUT_TO_LENGTH = 'cut to length';

/**
 * @const
 */
staticSelf.PACKAGE_TYPE_FIXED_QUANTITY = 'fixed quantity';

/**
 * @const
 */
staticSelf.FIELD_SOURCE_SELF = 'self';

/**
 * @const
 */
staticSelf.FIELD_SOURCE_EXTERNAL = 'external';

/**
 * @const
 */
staticSelf.TAA_STATUSES = [
  'compliant',
  'unknown',
  'not_compliant',
];

/**
 * @const
 */
staticSelf.MFG_PART_STATUSES = [
  'active',
  'obsolete',
  'use_up',
];

/**
 * @const
 */
staticSelf.COMPANY_PART_STATUSES = [
  'active',
  'obsolete',
  'use_up',
];

/**
 * @const
 */
staticSelf.COMPANY_PART_CRITERIA_FIELDS = [
  'part_base_code',
  'class_name',
  'taa_status',
  'mfg_part_status',
  'company_part_status',
  'replacement_part',
  'package_type',
  'pkg_units',
  'uom',
  'sales_uom',
  'package_uom',
  'external_uom',
  'pricing_group',
];

/**
 * Corporate Part.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function CorporatePart(data) {
  BaseEntity.call(this, data);

  /**
   * Get part.
   *
   * @return {Part}
   */
  this.getPart = function() {
    return new Part({
      number: this.part,
      manufacturer_id: this.manufacturer_id,
      manufacturer_name: this.manufacturer_name,
      manufacturer_url_name: this.manufacturer_url_name,
      family_id: this.family_id,
      description_long: this.description_long,
      ordering_number: this.part_ordering_number,
      engineering_number: this.part_engineering_number,
    });
  };

  /**
   * Get UOM as object.
   *
   * @return {?object}
   */
  this.getUomAsObject = function() {
    if (!(this.uom_id > 0)) {
      return null;
    }

    return {
      id: this.uom_id,
      name: this.uom,
    };
  };

  /**
   * Determine whether the pricing is calculated.
   *
   * @return {boolean}
   */
  this.isPricingCalculated = function() {
    return staticSelf.PRICING_TYPE_CALCULATED === this.pricing_type;
  };

  /**
   * Determine whether the pricing is manual.
   *
   * @return {boolean}
   */
  this.isPricingManual = function() {
    return staticSelf.PRICING_TYPE_MANUAL === this.pricing_type;
  };

  // Initialize
  this._init();
}

export default CorporatePart;
