import BaseComponent from 'js/base_v3/components/component';
import ListExtension from 'js/common_v3/extensions/list-extension';

/**
 * Base View.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {DOMElement} container
 * @param {object}     [options]
 */
function BaseView(container, options) {
  BaseComponent.call(this, options);
  const parent = this.clone();

  /**
   * @prop {object}
   */
  this._fieldsRequired = null;

  /**
   * @protected
   *
   * @prop {DOMElement}
   */
  this._container = container;

  /**
   * @protected
   *
   * @prop {ListExtension}
   */
  this._listExtension = null;

  /**
   * Reload view.
   *
   * @return {BaseView}
   */
  this.reload = function() {
    return this;
  };

  /**
   * Adjust.
   *
   * @return {BaseView}
   */
  this.adjust = function() {
    return this;
  };

  /**
   * Get container.
   *
   * @return {DOMElement}
   */
  this.getContainer = function() {
    return this._container;
  };

  /**
   * @inheritDoc
   */
  this._initDefaults = function() {
    parent._initDefaults.call(this);

    return this._extendDefaultOptions({
      listExtension: {},
    });
  };

  /**
   * @inheritDoc
   */
  this._initProps = function() {
    parent._initProps.call(this);

    this._listExtension = new ListExtension(this, this._options.listExtension);

    return this;
  };
}

export default BaseView;
