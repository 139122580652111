import BaseSelect2Field from 'js/base_v2/select2-field';

/**
 * Select2 Field.
 *
 * @class
 * @extends {BaseSelect2Field}
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     [options]
 */
function SelectField(fieldCt, fieldEl, options) {
    BaseSelect2Field.call(this, fieldCt, fieldEl, options);
    var parent = this.clone();
    var self = this;

    // Initialize
    this.init();
}

export default SelectField;
