import BaseComponent from 'js/base_v2/component';
/**
 * Tabs.
 *
 * @class
 * @extends BaseComponent
 *
 * @param {DOMElement} ct
 * @param {object}     [options]
 */
function Tabs(ct, options) {
    BaseComponent.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {DOMElement}
     */
    this.ct = ct;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            loadActiveTabOnCreate: false,
            onTabShown: undefined
        });
    };

    /**
     * @inheritDoc
     */
    this.create = function() {
        parent.create.call(this);

        this.registerEventListeners()

        if (this.options.loadActiveTabOnCreate) {
            this.loadActiveTab();
        }

        return this;
    };

    /**
     * Register event listeners.
     *
     * @return {Tabs}
     */
    this.registerEventListeners = function() {
        this.ct.on('shown.bs.tab', 'a[data-toggle="tab"]', function(event) {
            self.onTabShown(self.getTabElFromButton($(event.target)));
        });

        return this;
    };

    /**
     * Tab shown event listener.
     *
     * @param {DOMElement} tabEl
     */
    this.onTabShown = function(tabEl) {
        if (_.isFunction(this.options.onTabShown)) {
            this.options.onTabShown(tabEl);
        }
    };

    /**
     * Load active tab.
     *
     * @return {Tabs}
     */
    this.loadActiveTab = function() {
        var activeTabButton = $('a[data-toggle="tab"].active', this.ct);

        if (0 === activeTabButton.length) {
            return this;
        }

        this.onTabShown(this.getTabElFromButton(activeTabButton));

        return this;
    };

    /**
     * Get tab element from button.
     *
     * @param  {DOMElement} tabButton
     * @return {DOMElement}
     */
    this.getTabElFromButton = function(tabButton) {
        return $(tabButton.attr('href'));
    };

    // Initialize
    this.init();
}

export default Tabs;
