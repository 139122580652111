import BaseComponent from 'js/base_v2/component';

/**
 * Base Details.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {DOMElement} detailsCt
 * @param {?object}    record
 * @param {object}     [options]
 */
function BaseDetails(detailsCt, record, options) {
  BaseComponent.call(this, options);
  const parent = this.clone();

  /**
   * @prop {DOMElement}
   */
  this.detailsCt = detailsCt;

  /**
   * @prop {?object}
   */
  this.record = record;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.defaultOptions = Object.extend(this.defaultOptions, {
      entityName: 'record',
      entityLabel: 'record',
      titleEl: undefined,
      tmplEl: undefined,
      tmplParams: {},
      emptyClassName: undefined,
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    if (_.isString(this.detailsCt)) {
      this.detailsCt = $(this.detailsCt);
    }

    return parent.create.call(this);
  };

  /**
   * Register event listeners.
   *
   * @return {BaseDetails}
   */
  this.registerEventListeners = function() {
    return this;
  };

  /**
   * @inheritDoc
   */
  this.destroy = function() {
    this.detailsCt.html('');
    return this;
  };

  /**
   * Load details.
   *
   * @param  {object}      [newRecord]
   * @return {BaseDetails}
   */
  this.load = function(newRecord) {
    this.record = !_.isUndefined(newRecord) ? newRecord : this.record;

    if (null === this.record) {
      return this.loadEmpty();
    }

    this
      .renderHeader()
      .renderTemplate()
      .prepareComponentsOptions()
      .createComponents()
      .registerEventListeners();

    return this;
  };

  /**
   * Load empty details.
   *
   * @return {BaseDetails}
   */
  this.loadEmpty = function() {
    return this
      .renderHeader()
      .renderEmptyTemplate();
  };

  /**
   * @return {DOMElement}
   */
  this.getEmptyTemplate = function() {
    const div = $('<div>').html(`
      <i class="fa fa-exclamation-circle"></i>
      No ${this.options.entityLabel} selected.
    `);

    if (this.options.emptyClassName) {
      div.addClass(this.options.emptyClassName);
    }

    return div;
  };

  /**
   * Render empty template.
   *
   * @return {BaseDetails}
   */
  this.renderEmptyTemplate = function() {
    this.detailsCt.html(
      this.getEmptyTemplate(),
    );

    return this;
  };

  /**
   * Render header.
   *
   * @return {BaseDetails}
   */
  this.renderHeader = function() {
    return this;
  };

  /**
   * Prepare template params.
   *
   * @return {BaseDetails}
   */
  this.prepareTmplParams = function() {
    return this;
  };

  /**
   * Render template.
   *
   * @return {BaseDetails}
   */
  this.renderTemplate = function() {
    this.prepareTmplParams();

    const html = $(this.options.tmplEl).tmpl(this.options.tmplParams);

    this.detailsCt.html(html);

    return this;
  };

  /**
   * Prepare components options.
   *
   * @return {BaseDetails}
   */
  this.prepareComponentsOptions = function() {
    return this;
  };

  /**
   * Create components.
   *
   * @return {BaseDetails}
   */
  this.createComponents = function() {
    return this;
  };

  /**
   * Get record.
   *
   * @return {object}
   */
  this.getRecord = function() {
    return this.record;
  };

  /**
   * Get container.
   *
   * @return {DOMElement}
   */
  this.getContainer = function() {
    return this.detailsCt;
  };
}

export default BaseDetails;
