import BaseEntity from 'js/base_v3/models/entity';
import Manufacturer from 'js/manufacturer/models/manufacturer';
/**
 * Part.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function Part(data) {
    BaseEntity.call(this, data);
    var parent = this.clone();
    var self = this;

    /**
     * Auto set ID.
     *
     * @return {Part}
     */
    this.autoSetId = function() {
        this.id = this._generateId();

        return this;
    };

    /**
     * Get manufacturer.
     *
     * @return {?Manufacturer}
     */
    this.getManufacturer = function() {
        if (!(this.manufacturer_id > 0)) {
            return null;
        }

        return new Manufacturer({
            id: this.manufacturer_id,
            name: this.manufacturer_name
        });
    };

    /**
     * Generate ID.
     *
     * @protected
     *
     * @return {number}
     */
    this._generateId = function() {
        return (this.number + '|' + (this.manufacturer_id || 0)).hashCode();
    };

    // Initialize
    this._init();
}

export default Part;
