import BaseEntity from 'js/base_v3/models/entity';
/**
 * Manufacturer.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function Manufacturer(data) {
    BaseEntity.call(this, data);
    var parent = this.clone();
    var self = this;

    // Initialize
    this._init();
}

export default Manufacturer;
